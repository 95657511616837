














































// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
// @ts-ignore
import * as pdfjs from 'pdfjs-dist/build/pdf'
import * as PDFMaker from '../../components/mixins/pdfmaker'
import Vue from 'vue'
import CancelButton from '@/components/buttons/CancelButton.vue'
import PrintButton from '@/components/buttons/PrintButton.vue'
import DownloadButton from '@/components/buttons/DownloadButton.vue'
import PrintDialog from '@/components/dialogs/PrintDialog.vue'
import { CREATE_PRINT_JOB, GetBase64StringFromGeneratedPdf } from '@/api/graphql/Constants/printNode'
import {
  CreatePrintJobInput,
  Mutation,
  Printnode_Content_Type
} from '@/models/generated/graphql/ErpBackend'

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export interface CustomOption {
  display: string
  onChange(data: any, model: boolean): void
  model: boolean
}

export default Vue.extend({
  name: 'ViewPDF',
  components: {
    'cancel-button': CancelButton,
    'print-button': PrintButton,
    'download-button': DownloadButton,
    'print-dialog': PrintDialog
  },
  props: {
    data: {
      type: Object as () => any,
      required: true
    },
    custom: {
      type: Array as () => Array<CustomOption>,
      required: false,
      default: () => []
    }
  },
  watch: {
    data (newval, oldval) {
      this.generateDocument()
      this.renderPDF()
    }
  },
  data () {
    return {
      configList: [],
      title: '',
      status: 'good',
      docdata: {} as any,
      pageHeight: 0,
      scale: 1,
      printing: false,
      printer: 0,
      showPrintDialog: false
    }
  },
  methods: {
    change: function (custom: any) {
      custom.onChange(this.data, custom.model)
      this.generateDocument()
      this.renderPDF()
    },

    print () {
      if (this.$store.getters['data/isElectron']) {
        this.showPrintDialog = true
      } else {
        window.open(this.generateDocument().output('bloburl'), '_blank')
      }
    },

    async sendPrintJob () {
      if (this.printer > 0) {
        this.printing = true
        try {
          const content = GetBase64StringFromGeneratedPdf(this.generateDocument().output('datauri'))
          await this.$apollo.mutate<Mutation, CreatePrintJobInput>({
            mutation: CREATE_PRINT_JOB,
            variables: {
              printerId: `${this.printer}`,
              content: content,
              contentType: Printnode_Content_Type.PdfBase64,
              source: 'a test',
              title: 'another test'
            }
          })
        } catch (error) {
        } finally {
          this.printing = false
        }
      }
    },

    download () {
      this.generateDocument().save(`${this.data.title}.pdf`)
    },

    setScale (value: number) {
      // this.scale = Math.max(Math.min(value, 5.0), 0.25)
      this.scale = Math.max(Math.min(value, 5.0), 1.0)
      // this.renderPDF()
    },

    toggleZoom () {
      this.scale = (this.scale === 1) ? 2 : 1
    },

    zoomDefault () {
      this.setScale(window.devicePixelRatio ?? 1)
      this.renderPDF()
    },

    zoomIn () {
      this.setScale(this.scale + 0.25)
      this.renderPDF()
    },

    zoomOut () {
      this.setScale(this.scale - 0.25)
      this.renderPDF()
    },

    async renderPDF () {
      document.getElementById('pdf')!.innerHTML = ''
      const pdf = await pdfjs.getDocument({ disableFontFace: false, data: this.docdata }).promise
      for (const n of Array(pdf.numPages).keys()) {
        const page = await pdf.getPage(n + 1)

        const viewport = page.getViewport({ scale: this.scale })
        const pixelRatio = window.devicePixelRatio || 1

        const pixelWidth = Math.ceil(viewport.width / pixelRatio)
        const pixelHeight = Math.ceil(viewport.height / pixelRatio)

        const canvas = document.createElement('canvas')
        canvas.width = Math.ceil(viewport.width)
        canvas.height = Math.ceil(viewport.height)
        canvas.className = 'page'
        canvas.style.width = `${pixelWidth}px`
        canvas.style.height = `${pixelHeight}px`
        // this.$refs['pdf'].push(canvas)
        document.getElementById('pdf')?.appendChild(canvas)
        const context = canvas.getContext('2d')
        const renderTask = await page.render({
          canvasContext: context!,
          viewport: viewport
        }).promise
      }
    },

    generateDocument () {
      const doc = PDFMaker.genPDF(this.data)
      this.docdata = new Uint8Array(doc.output('arraybuffer'))
      return doc
    }
  },
  beforeMount () {
  },
  mounted () {
    this.scale = window.devicePixelRatio ?? 1
    /* this.configList = [] */
    this.generateDocument()
    this.renderPDF()
    // this.zoomDefault()
    // this.renderPDF()
  }
})
