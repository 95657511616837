<template>
  <div>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      color="primary"
      :loading="loading"
      :disabled="disabled"
      @click="$emit('click')"
    >
      Print
    </v-btn>
    <v-btn
      v-else
      icon
      dark
      :loading="loading"
      :disabled="disabled"
      @click="$emit('click')"
    >
      <v-icon
        color="primary"
      >fal fa-print</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'PrintButton',
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style scoped>

</style>
