<template>
  <div>
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp"
      color="success"
      @click="$emit('click')"
    >
      Download
    </v-btn>
    <v-btn
      v-else
      icon
      dark
      @click="$emit('click')"
    >
      <v-icon
        color="success"
      >fal fa-download</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'DownloadButton'
}
</script>

<style scoped>

</style>
